import React from 'react';
import PageContent from './PageContent';
import Header from './Header';
import Footer from './Footer';
//import logo from '../logo.svg';


// Create a react component
class App extends React.Component {
   
  render() {

      return (
        <div>
            {/* <Header></Header> */}
            <PageContent></PageContent>
            {/* <Footer></Footer>   */}
        </div>
      );
  }
}

export default App;
