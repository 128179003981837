import React from 'react';
//import { Message, Dimmer, Loader } from 'semantic-ui-react';
import TopSection from './TopSection'
import Countdown from './Countdown'
import LoveStory from './LoveStory'
//import PlaceAndTime from './PlaceAndTime'
//import RSVP from './RSVP'
import Gallery from './Gallery'
import '../css/lovestory.css'
import '../css/fonts.css';
import '../css/gallery.css';

const PageContent = () => {
 
    return (
        <>
        {/* <div style={{display:'block', position:'static', top:'0', flow:'left'}}>
      */}
        <TopSection></TopSection> 
        {/* </div> */}
        {/* <div style={{display:'grid', position:'relative', flow:'left', marginBottom:'-40'}}> */}
        <Countdown targetDate="2025-06-14T11:00:00" targetTimezone='America/New_York'></Countdown>
        <LoveStory></LoveStory> 
        {/* <PlaceAndTime></PlaceAndTime>
        <RSVP></RSVP> */}
        <Gallery></Gallery>

        <br/><br/>
        
        {/* </div> */}
        </>
    );

};

export default PageContent;