import React from 'react';
//import { Message, Dimmer, Loader } from 'semantic-ui-react';
import '../css/fonts.css';
//import '../css/custom.css'
import '../css/topsection.css'

class TopSection extends React.Component {
    render() {
        return (
            <div id="home" className="hero set-bg" style={{ border: '0px Solid Green' }}>
                {/* <!-- data-setbg="media/2ff2db97-9d9a-4845-8cce-a26d68610322.jfif"> --> */}
                <div style={{ border: '0px Solid Pink' }}>
                    <div className="hero__text">
                        {/* <!-- <img src="img/icon/hi.png" alt> --> */}
                        <div className="hero__div">
                            <span className="headervignettes">t</span>
                            <span className="timesnewromantext">THE WEDDING OF</span>
                            <span className="headervignettes">u</span>
                         </div>   
                         <div className="hero__div">&nbsp;</div>
                        <div className="hero__div titletext">
                            Nicole & Michael
                        </div>
                        
                        <div className="hero__div timesnewromantext">06.14.2025</div>
                    </div>
                </div>
            </div>
        );
    }
}

export default TopSection;