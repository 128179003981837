import React, { useState, useEffect }  from 'react';
import { Message, Dimmer, Loader } from 'semantic-ui-react';
import moment from 'moment-timezone';
import '../css/fonts.css';
//import '../css/custom.css'
import '../css/countdown.css'
 
const Countdown = ({ targetDate, targetTimezone }) => {

    const [yearsRemaining, setYearsRemaining] = useState(0);
    const [monthsRemaining, setMonthsRemaining] = useState(0);
    const [daysRemaining, setDaysRemaining] = useState(0);
    const [hoursRemaining, setHoursRemaining] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
          const now = moment.tz(targetTimezone);
          const then = moment.tz(targetDate, targetTimezone).add(1, 'days');
          const countdown = moment.duration(then.diff(now));
 
        if (countdown.asMilliseconds() <= 0) {
            setYearsRemaining(0);
            setMonthsRemaining(0);
            setDaysRemaining(0);
            setHoursRemaining(0);
            clearInterval(interval);
          } else {
            const years = countdown.years();  
            const months = countdown.months();  
            const days = countdown.days();  
            const hours = countdown.hours();


            setYearsRemaining(years);
            setMonthsRemaining(months);
            setDaysRemaining(days);
            setHoursRemaining(hours);

          }
        }, 1000);
    
        return () => clearInterval(interval);
      }, [targetDate, targetTimezone]);
    



    return (

        <div id="countdown" className="countdown-main">              
            <div className="countdown-outer">
            <div className="countdown-element">
                <p className="countdown-number">
                    {yearsRemaining}
                </p>
                <p className="countdown-text">
                    year
                </p>
            </div>
            <div className="countdown-element">
                <p className="countdown-number">
                    {monthsRemaining}
                </p>
                <p className="countdown-text">
                    months
                </p>
            </div>
            <div className="countdown-element">
                <p className="countdown-number">
                    {daysRemaining}
                </p>
                <p className="countdown-text">
                    days
                </p>
            </div>
            <div className="countdown-element">
                <p className="countdown-number">
                    {hoursRemaining}
                </p>
                <p className="countdown-text">
                    hours
                </p>
            </div>
            </div>
        </div>  
    );
};

export default Countdown;