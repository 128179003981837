import React from 'react';
//import ReactDOM from 'react-dom/client';
import { createRoot } from 'react-dom/client';
import App from './components/App';
import 'semantic-ui-css/semantic.min.css';
import './css/index.css';

//import reportWebVitals from './reportWebVitals';



const container = document.getElementById('root');
const root = createRoot(container);  
root.render(<App tab="home" />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
